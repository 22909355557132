<template>
  <div class="enabling-bg">
    <div class="container">
      <div class="row">
        <p>
          Digimarketz : Enabling People To Sel Products or Services Through Online Shops
        </p>
      </div>
    </div>
  </div>

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 pt-2 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"
        >
          <p class="fw-bold">Create,Host & Sell Your Products or Services Online</p>
          <h1 class="mb-2">Launch your own ECommerce Platform</h1>
          <span class="fst-italic fs-4 mt-2 mb-1"
            >Own Your Customers, <br />
            Build Your Brand Online</span
          >

          <div class="but-box mt-4">
            <a href="#">Get Started for FREE now...!!</a>
          </div>

          <div class="row mt-5 rounded-1 header-list-box">
            <div class="col-lg-4 col-md-4">
              <div>
                <div class="icon-box">
                  <img src="assets/img/dollar.png" alt="" />
                  <h3 class="text-white">30 Days Money-back Guarantee</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 mt-4 mt-md-0">
              <div>
                <div class="icon-box">
                  <img src="assets/img/rejected.png" alt="" />

                  <h3 class="text-white">Cencel Any Times</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 mt-4 mt-md-0">
              <div>
                <div class="icon-box">
                  <img src="assets/img/cust-serv.png" alt="" />
                  <h3 class="text-white">24/7 Support</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img">
          <img src="assets/img/digimarketz.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->

  <main id="main">
    <!-- =======  What you can do with Digimarketz..? ======= -->
    <section id="what-you-can-box" class="what-you-can-box">
      <div class="container">
        <div class="text-center">
          <h1 class="fw-bold fs-1">What you can do with Digimarketz..?</h1>
          <p>
            Book a FREE demo for any store below, See how order is placed,and get
            inspired!
          </p>
        </div>
        <div class="container">
          <div class="row mt-4 mb-4 what-you-list">
            <ul>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/sample/groceries_online_store.jpeg" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Grocery</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/sample/supermarket_online_store.jpeg" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Supermarket</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/sample/furniture_online_store.jpeg" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Furnitures</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img
                      src="assets/img/sample/food_beverages_online_store.jpeg"
                      alt=""
                    />
                  </div>
                  <div class="text-box">
                    <h3>Food & Beverages</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="http://demo-clothing.digimarketz.com/">
                  <div class="img-box">
                    <img src="assets/img/sample/clothing_online_store.jpeg" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Clothing</h3>
                  </div>
                </a>
              </li>
              <!-- <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Electronics</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Gifts</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Digital Products</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Jewelry</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Auto Spares</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Shoes</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Handicrafts</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Organic Products</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Arts & Photos</h3>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="img-box">
                    <img src="assets/img/services.png" alt="" />
                  </div>
                  <div class="text-box">
                    <h3>Accessories</h3>
                  </div>
                </a>
              </li> -->
            </ul>
          </div>
          <div class="but-box mt-4 text-center">
            <a href="#">Get Started for FREE now...!!</a>
          </div>
        </div>
      </div>
    </section>
    <!-- =======  END What you can do with Digimarketz..? ======= -->

    <FeaturesSection />

    <!-- =======  Delivery and Payment Partners======= -->
    <section>
      <div class="container">
        <div class="row content">
          <div class="col-lg-6 del-part text-center">
            <h1 class="fw-bold fs-1">Delivery Partners</h1>
            <p>Delivery your products directly from your online store</p>
            <div class="directly-list text-center">
              <ul>
                <li><img src="assets/img/deli-1.png" alt="" /></li>
                <li><img src="assets/img/deli-2.png" alt="" /></li>
                <li><img src="assets/img/deli-3.png" alt="" /></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 del-part text-center">
            <h1 class="fw-bold fs-1">Payment Partners</h1>
            <p>Receive payments directly into your bank account</p>
            <div class="payment-list text-center">
              <ul>
                <li><i class="bi bi-people"></i> <span class="mt-4">Cod</span></li>
                <li><i class="bi bi-people"></i> <span>Paypal</span></li>
                <li><i class="bi bi-people"></i> <span>Razorpay</span></li>
                <li><i class="bi bi-people"></i> <span>Paystack</span></li>
                <li><i class="bi bi-people"></i> <span>Stripe</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- =======  END Delivery and Payment Partners======= -->

    <!-- ======= latest Updates ======= -->
    <section id="why-us" class="why-us">
      <div class="text-center">
        <h1 class="fw-bold fs-1">Latest Updates..</h1>
        <p>Learn about latest and advanced techniques to grow your online business</p>
      </div>
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-4">
            <div class="box">
              <img src="assets/img/hero-img.png" alt="" />
              <span>01</span>
              <h4>Lorem Ipsum</h4>
              <p>
                Ulamco laboris nisi ut aliquip ex ea commodo consequat. Et consectetur
                ducimus vero placeat
              </p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0">
            <div class="box">
              <img src="assets/img/hero-img.png" alt="" />
              <span>02</span>
              <h4>Lorem Ipsum</h4>
              <p>
                Dolorem est fugiat occaecati voluptate velit esse. Dicta veritatis dolor
                quod et vel dire leno para dest
              </p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0">
            <div class="box">
              <img src="assets/img/hero-img.png" alt="" />
              <span>03</span>
              <h4>Lorem Ipsum</h4>
              <p>
                Molestiae officiis omnis illo asperiores. Aut doloribus vitae sunt debitis
                quo vel nam quis
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End latest Updates -->

    <!-- ======= Frequently Asked Questions Section ======= -->
    <Faqs />
    <!-- End Frequently Asked Questions Section -->

    <!-- End About Section -->

    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts section-bg">
      <div class="section-title">
        <h1 class="fw-bold fs-1">Anyone can have their own ecommerce website</h1>
      </div>
      <div class="container">
        <div class="row">
          <div class="but-box mt-2 text-center">
            <a href="#">Get Started for FREE now...!!</a>
            <h3 class="fst-italic">
              Anyone can have their own ecommerce website No Commitments, Cancel Anytime
              with 30 Days Moneybank Guarantee
            </h3>
          </div>
        </div>
      </div>
    </section>
    <!-- End Counts Section -->
  </main>
  <!-- End #main -->

  <!-- ======= Footer ======= -->
 
</template>

<script>
import HomeData from "@/json/homedata";
import FeaturesSection from "@/components/FeaturesSection";
import Faqs from "@/components/Faqs";

export default {
  name: "Home",

  components: {
    FeaturesSection,
    Faqs,
  },

  data() {
    return {
      HomeData: HomeData,
      bgLightGreen: "bg-light-green",
    };
  },
};
</script>
