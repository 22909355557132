<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row text-center">
          <div class="footer-img">
            <img src="assets/img/logo.png" alt="Digimarketz" />
            <p>Get tool that grow your business</p>
          </div>

          <div class="footer-links">
            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#"> Terms & Conditions </a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Refund & Cancellation </a></li>
              <li><a href="#"> Contact Us </a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4">
      <div class="social-links text-center text-md-right text-md-start me-md-auto">
        <a href="#" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
        <a href="#" class="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
        <a href="#" class="instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a>
        <a href="#" class="youtube"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
        <a href="#" class="linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
      </div>

      <div class="pt-3 cdb pt-md-0">
        <div class="copyright text-right">
          &copy; Copyright <strong><span>Digimarketz</span></strong
          >. All Rights Reserved
        </div>
      </div>
    </div>
  </footer>
  <!-- End Footer -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
</template>

<script>
export default {

}
</script>

<style>

</style>