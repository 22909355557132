<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <!-- ======= Counts Section ======= -->
    <div class="live-domo-bg">
      <div class="container">
        <div class="row">
          <p>schedule A Live Demo - <a href="#">Click Here</a> to Reserve Spot Now</p>
        </div>
      </div>
    </div>
    <!-- End Counts Section -->
    <div class="container d-flex align-items-center justify-content-between py-1">
      <div class="logo">
        <a href="/"> <img src="assets/img/logo.png" alt="Digimarketz" /></a>
      </div>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
          <li>
            <router-link to="pricing" class="nav-link scrollto">Pricing</router-link>
          </li>
          <li><a class="nav-link scrollto" href="#">Member Login</a></li>
          <li><a class="nav-link scrollto" href="#">Start for FREE</a></li>
          <!-- <li><a class="nav-link scrollto" href="#team">Team</a></li>
                    <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                        <ul>
                            <li><a href="#">Drop Down 1</a></li>
                            <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                                <ul>
                                    <li><a href="#">Deep Drop Down 1</a></li>
                                    <li><a href="#">Deep Drop Down 2</a></li>
                                    <li><a href="#">Deep Drop Down 3</a></li>
                                    <li><a href="#">Deep Drop Down 4</a></li>
                                    <li><a href="#">Deep Drop Down 5</a></li>
                                </ul>
                            </li>
                            <li><a href="#">Drop Down 2</a></li>
                            <li><a href="#">Drop Down 3</a></li>
                            <li><a href="#">Drop Down 4</a></li>
                        </ul>
                    </li> 
                    <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
                    <li><a class="getstarted scrollto" href="#about">Get Started</a></li>-->
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
  <!-- End Header -->
  <router-view />
  <Footer />
</template>
<script>
import Footer from "@/components/Footer";
export default {
  components: {    
    Footer
  },
};
</script>