<template>
  <section id="built-tools-box" class="built-tools-box">
    <div class="container">
      <div class="text-center">
        <h1 class="fw-bold fs-1">Built in tools to increase your online sales</h1>
      </div>
      <div class="container">
        <div class="row mt-4 mb-4">
          <div class="online-sales text-left">
            <div id="features" style="padding: 10px" class="features base row">
              <div
                v-for="(feature, index) in HomeData.features"
                :key="index"
                style="padding: 0"
                class="col-lg-4 col-md-6 col-12"
              >
                <div
                  style="padding: 20px 0px; margin: auto; height: 100%; background: white"
                  :class="[{ [bgLightGreen]: (index + 1) % 2 != 0 }, 'row']"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: center"
                    class="col-3"
                  >
                    <img
                      style="max-width: 4em"
                      :src="'assets/img/features/' + feature.image"
                      :alt="feature.title"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    style="display: flex; text-align: left; justify-content: center"
                    class="col-9"
                  >
                    <span>
                      <h4 class="color-primary">{{ feature.title }}</h4>
                      <p class="color-muted" style="font-size: 0.9em">
                        {{ feature.description }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HomeData from "@/json/homedata";
export default {
  name: "FeaturesSection",
  data() {
    return {
      HomeData: HomeData,
      bgLightGreen: "bg-light-green",
    };
  },
};
</script>
