<template>
<section id="faq" class="faq">
      <div class="container">
        <div class="section-title">
          <h1 class="fw-bold fs-1">Frequently Asked Questions</h1>
        </div>

        <ul class="faq-list">
          <li>
            <div data-bs-toggle="collapse" class="collapsed question" href="#faq1">
              What is DigiMarketz, and how does it work? 
               <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>
            </div>
            <div id="faq1" class="collapse" data-bs-parent=".faq-list">
              <p>
               DigiMarketz is a straightforward online platform for organizing, maintaining, and expanding your company. You may create your own online shop, add items, manage inventory, and accept payments online, among other things. It is the easiest and most effective method of expanding your company. 

              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq2" class="collapsed question">
             What kinds of companies may profit from DigiMarketz? 
               <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>
            </div>
            <div id="faq2" class="collapse" data-bs-parent=".faq-list">
              <p>
               Anyone who wants to establish or grow an e-commerce business. 
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq3" class="collapsed question">
             Is it required for me to pay order commission? 
              <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>
            </div>
            <div id="faq3" class="collapse" data-bs-parent=".faq-list">
              <p>
                DigiMarketz does not charge a commission till you reach Rs.1,000,000 in monthly income. Your profits are depending on your sales, and if you exceed Rs. 1,00,000 in transactions per month, you will be charged 0.75% of each transaction. 

              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq4" class="collapsed question">
              How should I go about accepting online payments? 
               <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>
            </div>
            <div id="faq4" class="collapse" data-bs-parent=".faq-list">
              <p>
                Your shop accepts a variety of payment methods, including credit and debit cards, net banking, UPI, and wallets. To receive payments directly, you must first create an account with the payment provider. 

              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq5" class="collapsed question">
              Does DigiMarketz support delivery? 
               <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>
            </div>
            <div id="faq5" class="collapse" data-bs-parent=".faq-list">
              <p>
               Yes, you may choose from several delivery partners to have your items delivered straight from your online shop. 
Please keep in mind that delivery is subject to partner availability. 
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq6" class="collapsed question">
             How should I go about connecting my own domain? 

                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq6" class="collapse" data-bs-parent=".faq-list">
              <p>
                You may use the DigiMarketz Premium package to connect your personal domain to your online company. 
Please keep in mind that the plan does not include a domain name. 

              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq7" class="collapsed question">
            How will my orders be delivered to me? 
                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq7" class="collapse" data-bs-parent=".faq-list">
              <p>You will get order alerts by text message, WhatsApp, and email. 
              </p>
            </div>
          </li>
           <li>
            <div data-bs-toggle="collapse" href="#faq8" class="collapsed question">
          How long will it take for my shop to be operational? 
                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq8" class="collapse" data-bs-parent=".faq-list">
              <p>When you join up, one of our staff members will contact you and assist you in getting your online shop up and running as soon as possible. All you must do now is set up a business and begin selling. 
              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq9" class="collapsed question">
         Can I add and remove goods at any time? 
                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq9" class="collapse" data-bs-parent=".faq-list">
              <p>You may manage, customize, and update your product catalogue at any time and from any device. 
              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq10" class="collapsed question">
        Will DigiMarketz provide customer service? 

                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq10" class="collapse" data-bs-parent=".faq-list">
              <p>You may reach out to our customer care staff at any time using the built-in chat tool or just reach us over a call at our office hours.

              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq11" class="collapsed question">
        How can I build an e-commerce website from the ground up? 
                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq11" class="collapse" data-bs-parent=".faq-list">
              <p>Creating a website to sell online does not have to cost a lot of money or effort. DigiMarketz's ready-to-use e-commerce is fast and easy to use, and it contains all the tools you'll need to set up your online shop for an affordable cost without needing to be tech-savvy. 

              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq12" class="collapsed question">
        How much does DigiMarketz cost? 

                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq12" class="collapse" data-bs-parent=".faq-list">
              <p>The DigiMarketz app is available for free download. To get access to premium services, you may sign up for a 14-day free trial of DigiMarketz online. After the trial time expires, you may choose the pricing plan that best suits your company's requirements. 

              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq13" class="collapsed question">
       Is it required for me to have the technical knowledge to use DigiMarketz? 
                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq13" class="collapse" data-bs-parent=".faq-list">
              <p>No, you do not need any technical experience to establish an online company with DigiMarketz. DigiMarketz's suite of marketing tools, plugins and performance marketing makes it easy for anybody to establish and grow an online company. 

              </p>
            </div>
          </li>
          <li>
            <div data-bs-toggle="collapse" href="#faq14" class="collapsed question">
       Is it feasible to personalize my online store? 

                  <i class="fa fa-plus icon-show" aria-hidden="true"></i>
                  <i class="fa fa-minus icon-close" aria-hidden="true"></i>

                
            </div>
            <div id="faq14" class="collapse" data-bs-parent=".faq-list">
              <p>You may, indeed, personalize your online shop. You may choose from several themes (on request) that will suit your brand and make your business stand out.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  
</template>

<script>

</script>

<style>

</style>